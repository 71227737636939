import React, { useState } from 'react';
import {
    DesktopOutlined,
    FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
  } from '@ant-design/icons';
  import type { MenuProps } from 'antd';
  import { Breadcrumb, Layout, Menu, theme } from 'antd';
import SideContent from '../components/SideContent';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Employment from '../pages/Employment';
  
  const { Header, Content, Footer, Sider } = Layout;
  
  type MenuItem = Required<MenuProps>['items'][number];
  
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }
  
  const items: MenuItem[] = [
    getItem('Option 1', '1', <PieChartOutlined />),
    getItem('About Me', 'sub1', <UserOutlined />, [
      getItem('Education', '3'),
      getItem('Employment', '4'),
      getItem('Experience', '5'),
    ]),
    getItem('Transformation', 'sub2', <TeamOutlined />, [
      getItem('Business', '6'), 
      getItem('People', '7'),
      getItem('Technology', '8')
    ]),
    getItem('Skills', '9', <FileOutlined />),
    getItem('Articles', '2', <DesktopOutlined />),
  ];

const LayoutSlider: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    
    <Layout style={{ minHeight: '100vh' }}>
      <SideContent/>
      
      <Layout className="site-layout">
        
        <Content className="main-content" style={{ margin: '0 16px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb>
          <Routes>
            <Route path={'/'} element={<Home/>}/>
            <Route path={'/employment'} element={<Employment/>}/>
        </Routes>
        </Content>
        <Footer style={{ textAlign: 'center' }}>©2023 Binh Tran</Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutSlider;