import React, { useState } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import LayoutSlider from './layouts/LayoutSider';

const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Option 1', '1', <PieChartOutlined />),
  getItem('About Me', 'sub1', <UserOutlined />, [
    getItem('Education', '3'),
    getItem('Employment', '4'),
    getItem('Experience', '5'),
  ]),
  getItem('Transformation', 'sub2', <TeamOutlined />, [
    getItem('Business', '6'), 
    getItem('People', '7'),
    getItem('Technology', '8')
  ]),
  getItem('Skills', '9', <FileOutlined />),
  getItem('Articles', '2', <DesktopOutlined />),
];

const App: React.FC = () => {
  return (
    <div>
       <BrowserRouter>
          <LayoutSlider/>
       </BrowserRouter>
    </div>
 );
};


// const App: React.FC = () => {
//   const [collapsed, setCollapsed] = useState(false);
//   const {
//     token: { colorBgContainer },
//   } = theme.useToken();

//   return (
    
//     <Layout style={{ minHeight: '100vh' }}>
//       <Sider collapsible collapsed={collapsed} onCollapse={(value:any) => setCollapsed(value)}>
//         <div style={{  margin: 16,  justifyContent: 'center', display: 'inline-block' }}>
//             <img className='custom-avt' src="me.jpg" alt="" />
//             <p style={{color:'white', textAlign: 'center', fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>BINH TRAN</p>
//         </div>
//         <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
//       </Sider>
//       <Layout className="site-layout">
//         <Header style={{ padding: 0, background: colorBgContainer }} />
//         <Content style={{ margin: '0 16px' }}>
//           <Breadcrumb style={{ margin: '16px 0' }}>
//             <Breadcrumb.Item>User</Breadcrumb.Item>
//             <Breadcrumb.Item>Bill</Breadcrumb.Item>
//           </Breadcrumb>
//           <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
//             Bill is a cat.
//           </div>
//         </Content>
//         <Footer style={{ textAlign: 'center' }}>©2023 Binh Tran</Footer>
//       </Layout>
//     </Layout>
//   );
// };

export default App;