import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import type { MenuProps } from 'antd';
import { OptimizeIcon, SolutionIcon } from '../icons/Icons'; 
import {
    ProfileOutlined,
    StarOutlined,
    FileOutlined,
    PieChartOutlined,
    RiseOutlined,
    SolutionOutlined,
  } from '@ant-design/icons';
import { Link, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Employment from '../pages/Employment';

const { Sider } = Layout;


type MenuItem = Required<MenuProps>['items'][number];
  
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
const items: MenuItem[] = [
    getItem(<Link to={'/'}>About Me</Link>, '1', <SolutionOutlined />),
    getItem('Expertise Areas', 'sub1', <StarOutlined />, [
        getItem('Business Strategy', '2'),
        getItem('Technology', '3'),
      ]),
    getItem(<Link to={'Employment'}>Employment</Link>, '4', <ProfileOutlined />),

    getItem('Solution Sets', 'sub2', <SolutionIcon />, [
      getItem('Optimization', '5', <OptimizeIcon />), 
      getItem('Growth', '6', <RiseOutlined />),
    ]),
    getItem('Skills', '9', <FileOutlined />),
    
  ];

const SideContent: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);
  
    return (
        <Sider collapsible collapsed={collapsed} onCollapse={(value:any) => setCollapsed(value)} theme="light">
        <div style={{  margin: 16,  justifyContent: 'center', display: 'inline-block' }}>
            <img className='custom-avt' src="me.jpg" alt="" />
            <p style={{textAlign: 'center', fontWeight: 'bold', fontSize: '18px', marginBottom: '10px'}}>BINH TRAN</p>
        </div>
        <Menu defaultSelectedKeys={['1']} mode="inline" items={items} />
      </Sider>
    );
};

export default SideContent;