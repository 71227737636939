import { RcFile } from "antd/es/upload";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

const MarkdownContent = ({content}:{content:any}) => {
    
    const [tosText, setTosText] = useState('')
    useEffect(() => {
		fetch(content).then(res => res.text()).then(text => setTosText(text))
	})
    return (
       <ReactMarkdown children={tosText}/>
    )
};

export default MarkdownContent;