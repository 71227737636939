import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const OptimizedSvg = () => (
    <svg id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 100 100"
        width="1.2em"
        version="1.1">
        <path
            d="M86.62,49.08l-5.69-.45-2.18-5.27c-.33-.81-.88-.81-1.22,0l-2.18,5.27-5.69.45c-.87.07-1,.59-.38,1.16l4.34,3.71L72.29,59.5c-.21.85.24,1.17,1,.71l4.87-3,4.87,3c.74.45,1.19.13,1-.71l-1.33-5.55L87,50.24C87.66,49.67,87.5,49.15,86.62,49.08Z"
            id="path15"
        />
        <path
            d="M39.74,55.27,23.91,26.33A49.26,49.26,0,0,0,.85,67.52a2.41,2.41,0,0,0,2.35,2.66H33.88A16.82,16.82,0,0,1,39.74,55.27Z"
            id="path17"
        />
        <path
            d="M60.94,55.09,74.67,29.9A45.33,45.33,0,0,1,95.51,64.61c.06.77-.28,1.4-1.37,1.4H67a15.4,15.4,0,0,1,0,4.17H97.93a2.07,2.07,0,0,0,1.92-1.81A49.55,49.55,0,0,0,27.49,24.19L43.26,53C48.6,50.91,54.91,50.46,60.94,55.09Z"
            id="path19"
        />
        <path
            d="M68.1,57.84,55.33,60a9.45,9.45,0,1,0,4.56,8.42l8.76-9.76C69.07,58.23,68.92,57.71,68.1,57.84Z"
            id="path21"
        />
    </svg>

);

const SolutionSvg = () => (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 535.658 535.658" width="1.5em" >
<g>
	<path d="M295.027,91.431c25.202,0,45.705-20.508,45.705-45.716C340.732,20.508,320.229,0,295.027,0
		c-25.207,0-45.714,20.508-45.714,45.715C249.313,70.923,269.82,91.431,295.027,91.431z"/>
	<path d="M164.08,279.878c2.674,0,5.183-1.036,7.064-2.917l32.96-32.959c1.881-1.882,2.917-4.391,2.917-7.064
		s-1.036-5.183-2.917-7.064l-9.64-9.641c0.462-0.395,0.915-0.804,1.349-1.237c4.542-4.542,6.96-10.641,6.847-17.212
		c1.874-1.243,3.539-2.814,4.896-4.664l15.702-21.41c2.268-3.092,7.515-8.369,10.593-10.655l1.253-0.93l-13.608,54.243
		c-0.168,0.671-0.263,1.332-0.317,1.985l-11.245,47.8c-0.972,4.129-3.594,11.351-5.499,15.149l-27.289,54.338
		c-2.446,4.871-2.849,10.402-1.135,15.576c1.714,5.174,5.341,9.371,10.212,11.816c2.723,1.368,5.638,2.098,8.665,2.169
		c0.159,0.004,0.318,0.006,0.477,0.006c7.771,0,14.764-4.311,18.25-11.252l27.291-54.341c3.306-6.587,7.069-16.956,8.754-24.12
		l10.925-46.439h18.93c0.009,0.008,0.016,0.017,0.025,0.023l26.137,22.702l-12.928,51.716c-2.728,10.916,3.933,22.017,14.849,24.746
		c1.631,0.407,3.3,0.613,4.962,0.613c9.373-0.001,17.507-6.359,19.782-15.461l13.413-53.656c3.238-12.95-1.727-28.703-11.805-37.456
		l-25.397-22.061l8.708-34.717c5.185,10.155,16.115,18.897,27.093,21.508l36.9,8.778c1.476,0.352,2.987,0.529,4.494,0.529
		c8.993,0,16.729-6.118,18.814-14.878c2.469-10.382-3.968-20.838-14.349-23.308l-36.55-8.694c-0.545-0.279-1.606-1.151-1.984-1.632
		l-17.931-39.223c-3.141-6.872-10.054-11.312-17.612-11.312c-0.279,0-53.171,0-53.171,0c-4.025,0-8.175,2.029-10.581,3.814
		l-41.607,30.886c-6.15,4.565-14.204,12.667-18.734,18.845l-15.701,21.409c-0.418,0.57-0.795,1.162-1.146,1.766
		c-5.936,0.292-11.422,2.689-15.558,6.825c-0.435,0.435-0.843,0.888-1.238,1.35l-9.64-9.64c-1.881-1.881-4.39-2.917-7.064-2.917
		s-5.183,1.036-7.064,2.917l-32.959,32.96c-1.881,1.882-2.918,4.391-2.918,7.064s1.036,5.183,2.918,7.064l55.342,55.343
		C158.897,278.842,161.406,279.878,164.08,279.878z M168.033,191.213c1.329-1.33,2.96-2.29,4.763-2.841
		c0.011,0.082,0.013,0.163,0.025,0.244c0.786,5.109,3.515,9.607,7.684,12.665c2.95,2.163,6.375,3.423,9.979,3.69
		c-0.482,2.144-1.517,4.083-3.063,5.629c-0.437,0.436-0.913,0.822-1.41,1.179l-19.156-19.156
		C167.211,192.128,167.595,191.65,168.033,191.213z"/>
	<path d="M436.9,402.838c0-4.763-3.577-8.77-8.318-9.321l-10.528-1.232c-1.563-5.163-3.71-10.115-6.395-14.746l6.565-8.258
		c2.958-3.743,2.652-9.103-0.711-12.467l-13.186-13.188c-1.77-1.769-4.124-2.742-6.629-2.742c-2.107,0-4.181,0.722-5.84,2.033
		l-8.798,6.967c-4.354-2.313-8.922-4.175-13.606-5.545l-1.3-11.249c-0.548-4.716-4.553-8.292-9.323-8.32H340.19
		c-4.764,0-8.771,3.577-9.32,8.32l-1.298,11.152c-4.936,1.405-9.715,3.343-14.234,5.772l-8.968-7.099
		c-1.658-1.31-3.732-2.031-5.839-2.031c-2.506,0-4.86,0.974-6.629,2.743l-13.189,13.188c-3.362,3.363-3.668,8.724-0.708,12.47
		l6.815,8.595c-2.708,4.772-4.868,9.862-6.431,15.157l-10.764,1.254c-4.717,0.549-8.294,4.553-8.321,9.322l0.001,18.641
		c0,4.766,3.578,8.772,8.317,9.319l10.578,1.253c1.498,5.311,3.567,10.361,6.161,15.042l-6.337,7.985
		c-2.957,3.745-2.651,9.107,0.712,12.469l13.188,13.187c1.769,1.77,4.123,2.743,6.629,2.743c2.107,0,4.18-0.722,5.838-2.032
		l7.868-6.222c4.589,2.591,9.531,4.676,14.716,6.21l1.144,9.817c0.546,4.716,4.548,8.292,9.318,8.32h18.598
		c4.765,0,8.772-3.577,9.321-8.321l1.098-9.486c5.454-1.5,10.729-3.661,15.706-6.433l7.674,6.071
		c1.658,1.309,3.732,2.031,5.839,2.031c2.507,0,4.861-0.975,6.629-2.744l13.188-13.186c3.364-3.364,3.67-8.724,0.711-12.467
		l-6.069-7.673c2.848-5.102,5.055-10.54,6.571-16.193l9.876-1.142c4.714-0.546,8.292-4.549,8.322-9.321L436.9,402.838z
		 M370.061,434.056c-5.53,5.53-12.898,8.576-20.749,8.576c-7.85,0-15.218-3.046-20.747-8.576c-5.53-5.53-8.576-12.897-8.576-20.747
		c0-7.85,3.045-15.218,8.576-20.748c5.529-5.53,12.898-8.576,20.747-8.576c7.85,0,15.219,3.046,20.749,8.576
		c5.53,5.53,8.576,12.899,8.576,20.748C378.637,421.158,375.591,428.525,370.061,434.056z"/>
	<path d="M267.518,445.289c-0.417-4.993-4.503-8.862-9.498-9.001l-7.76-0.248c-1.706-4.22-3.865-8.248-6.431-12l4.25-6.428
		c2.757-4.152,1.968-9.729-1.842-12.979l-10.434-8.797c-1.781-1.495-4.042-2.319-6.366-2.319c-2.51,0-4.897,0.944-6.723,2.657
		l-6.023,5.65c-3.776-1.614-7.766-2.86-11.887-3.713l-1.663-8.19c-0.99-4.843-5.462-8.275-10.474-7.853l-13.59,1.146
		c-4.992,0.418-8.861,4.505-8.998,9.498l-0.272,8.275c-4.114,1.574-8.002,3.548-11.581,5.881l-7.055-4.683
		c-1.612-1.071-3.49-1.637-5.433-1.637c-2.912,0-5.66,1.265-7.547,3.477l-8.797,10.435c-3.216,3.83-3.07,9.457,0.337,13.087
		l5.516,5.889c-1.936,4.238-3.396,8.69-4.351,13.265l-7.794,1.593c-4.893,1.001-8.268,5.502-7.851,10.472l1.146,13.592
		c0.421,4.991,4.507,8.86,9.502,8.999l7.753,0.227c1.649,4.362,3.757,8.479,6.278,12.267l-4.07,6.156
		c-2.758,4.151-1.97,9.729,1.842,12.98l10.435,8.796c1.782,1.496,4.043,2.32,6.367,2.32c2.509,0,4.896-0.944,6.72-2.657l5.283-4.956
		c4.127,1.872,8.452,3.285,12.885,4.211l1.403,6.979c0.883,4.622,4.931,7.977,9.627,7.978h0.001c0.273,0,0.548-0.012,0.827-0.035
		l13.587-1.147c4.991-0.417,8.862-4.503,9.002-9.496l0.222-6.813c4.482-1.654,8.792-3.859,12.835-6.567l5.872,3.882
		c1.612,1.071,3.49,1.637,5.433,1.637c2.912,0,5.661-1.265,7.547-3.477l8.798-10.435c3.214-3.83,3.069-9.457-0.336-13.087
		l-4.797-5.121c2.049-4.567,3.529-9.326,4.409-14.177l7.037-1.425c4.894-0.998,8.27-5.5,7.852-10.478L267.518,445.289z
		 M211.407,475.701c-4.777,5.671-11.788,8.923-19.233,8.923c-5.93,0-11.684-2.106-16.206-5.933
		c-5.13-4.32-8.268-10.393-8.834-17.097c-0.567-6.705,1.507-13.219,5.842-18.343c4.776-5.67,11.786-8.922,19.233-8.922
		c5.929,0,11.684,2.106,16.206,5.932c5.13,4.322,8.267,10.396,8.833,17.101C217.815,464.066,215.741,470.578,211.407,475.701z"/>
</g>
</svg>
);
export const OptimizeIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={OptimizedSvg} {...props} />
);

export const SolutionIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={SolutionSvg} {...props} />
);